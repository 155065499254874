
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { ExemptionOrder } from '@/store/depot/types';
import ExemptionOrderComponent from '@/components/depot/ExemptionOrderComponent.vue';

@Component({
  name: 'exemption-order-view',
  components: {
    ExemptionOrderComponent,
  },
})
export default class DocumentsView extends Vue {
  @Getter('depot/exemptionOrder') exemptionOrder?: ExemptionOrder;
  @Getter('depot/loadingDepot') loadingDocuments?: Boolean;
}
