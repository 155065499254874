
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import {ExemptionOrder} from '@/store/depot/types';
import { currency } from '@/filters/currency';
import { getIdToken } from '@/utils/Auth';
import moment from 'moment';

const namespace: string = 'profile';

@Component({
  name: 'exemption-order',
  filters: {
    currency,
  },
})
export default class ExemptionOrderComponent extends Vue {
  @Prop({ required: true }) exemptionOrder: ExemptionOrder;

  @Action('depot/createPayOut') createPayOut: Function;

  downloadBaseData(fileName: string) {
    return process.env.VUE_APP_BAADER_DEPOT_API_URL + '/cockpit/base_data/' + fileName + '?jwt=' + getIdToken();
  }
  formatDate(date: string) {
    return date ? moment(date).format('DD.MM.YYYY') : null;
  }

}
